import variables from "../../../styles/variables.module.scss";

export enum LogoColors {
  Light,
  Green,
}

export enum LogoTextColors {
  White,
  Black,
}

export default function Logo({
  color,
  textColor,
  className,
  style,
}: {
  color: LogoColors;
  textColor?: LogoTextColors;
  className?: string;
  style?: any;
}) {
  let fill =
    color === LogoColors.Light ? variables["white"] : variables["logo-color"];
  let text =
    textColor === LogoTextColors.Black
      ? variables["body-color"]
      : variables["white"];

  return (
    <div className={className} style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 184.25 59.53"
        fill="none"
      >
        <g>
          <path
            stroke={text}
            fill={text}
            d="M78.71,1.42h-12.31V26.93h7.37v-6.6h4.94c5.47,0,9.44-3.97,9.44-9.44s-3.97-9.47-9.44-9.47Zm-1.62,13.27h-3.32V7.09h3.36c2.02,0,3.54,1.63,3.54,3.8s-1.54,3.8-3.58,3.8Z"
          />
          <rect
            stroke={text}
            fill={text}
            x="91.26"
            y="1.42"
            width="7.37"
            height="25.51"
          />
          <polygon
            stroke={text}
            fill={text}
            points="126.27 26.93 126.27 1.42 119.2 1.42 119.2 16.55 111.29 1.42 103.78 1.42 103.78 26.93 110.86 26.93 110.86 11.8 118.77 26.93 126.27 26.93"
          />
          <path
            stroke={text}
            fill={text}
            d="M146.06,23.94l.81,2.99h7.64L145.84,1.42h-8.08l-8.71,25.51h7.64l.81-2.99h8.56Zm-4.28-16.02l2.89,10.75h-5.77l2.89-10.75Z"
          />
        </g>
        <g>
          <polygon
            stroke={text}
            fill={text}
            points="82.78 38.05 82.78 32.6 66.48 32.6 66.48 58.11 82.78 58.11 82.78 52.66 73.85 52.66 73.85 48.04 82.78 48.04 82.78 42.85 73.85 42.85 73.85 38.05 82.78 38.05"
          />
          <path
            stroke={text}
            fill={text}
            d="M102.42,55.12l.81,2.99h7.64l-8.67-25.51h-8.08l-8.71,25.51h7.64l.81-2.99h8.56Zm-4.28-16.02l2.89,10.75h-5.77l2.89-10.75Z"
          />
          <path
            stroke={text}
            fill={text}
            d="M131.24,50.02c2.65-1.68,4.17-4.58,4.17-7.95,0-5.49-3.97-9.47-9.44-9.47h-12.31v25.51h7.37v-6.6h2.38l.04,.08,3.28,6.52h8.48l-4.08-8.02,.11-.07Zm-6.89-4.15h-3.32v-7.6h3.36c2.02,0,3.54,1.63,3.54,3.8s-1.54,3.8-3.58,3.8Z"
          />
          <polygon
            stroke={text}
            fill={text}
            points="158.12 32.6 137.43 32.6 137.43 38.9 144.07 38.9 144.07 58.11 151.48 58.11 151.48 38.9 158.12 38.9 158.12 32.6"
          />
          <polygon
            stroke={text}
            fill={text}
            points="183.06 58.11 183.06 32.6 175.68 32.6 175.68 42.41 168.2 42.41 168.2 32.6 160.83 32.6 160.83 58.11 168.2 58.11 168.2 48.49 175.68 48.49 175.68 58.11 183.06 58.11"
          />
        </g>
        <path
          fill={fill}
          d="M57.18,1.42H1.9c-.39,0-.71,.32-.71,.71V57.4c0,.39,.32,.71,.71,.71H57.18c.39,0,.71-.32,.71-.71V2.13c0-.39-.32-.71-.71-.71ZM31.57,49.51c-12.55,1.24-23.01-9.22-21.77-21.77,.92-9.29,8.43-16.8,17.72-17.72,12.55-1.24,23.01,9.22,21.77,21.77-.92,9.29-8.43,16.8-17.72,17.72Z"
        />
      </svg>
    </div>
  );
}
