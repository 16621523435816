import { useMemo } from "react";
import {
  Card as ReactStrapCard,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";

type CardProps = {
  title?: string | React.ReactNode;
  children?: string | React.ReactNode;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  className?: string;
};

const Card: React.FC<CardProps> = ({ title, children, size, className }) => {
  const body = useMemo(() => {
    if (typeof children === "string") {
      return <CardText>{children}</CardText>;
    } else {
      return <CardBody className={title ? "pt-0" : ""}>{children}</CardBody>;
    }
  }, [title, children]);
  return (
    <ReactStrapCard
      className={`${className} ${
        size && `card-${size}`
      } rounded-top-end rounded-bottom-start`}
    >
      {title && (
        <CardTitle tag="h2" className="m-4 mx-lg-8 mb-2 mt-8">
          {title}
        </CardTitle>
      )}
      {children && body}
    </ReactStrapCard>
  );
};

export default Card;
