import { useTranslation } from "next-i18next";
import Head from "next/head";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { Container } from "reactstrap";
import { Footer } from "../../_molecules/Footer/Footer";
import Navbar from "../../_molecules/Navbar/Navbar";
import variables from "../../../styles/variables.module.scss";
import CookieBot from "react-cookiebot";

export type LayoutProps = {
  title: string;
  /** provide a i18n namespace to signify that the provided string still needs to be translated **/
  ns?: string;
  children: React.ReactNode;
  light?: boolean;
  center?: boolean;
  wide?: boolean;
  className?: string;
};

const getBaseURL = () => {
  if(!!process.env.NEXT_PUBLIC_SITE_URL) {
    return process.env.NEXT_PUBLIC_SITE_URL;
  } else {
    return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }
}

const Layout: React.FC<LayoutProps> = ({
  title,
  ns,
  children,
  light,
  center,
  wide,
  className,
}) => {
  const { t } = useTranslation([ns ?? "", "common"]);
  const router = useRouter();
  const { locale } = router;

  const metaTitle = useMemo(() => (ns ? t(title) : title), [title, ns, t]);
  return (
    <div className="min-vh-100 d-flex flex-column">
      <Head>
        <title>Pina - {metaTitle}</title>
        <meta
          name="description"
          content={t("appDescription", { ns: "common" })}
        />
        <meta
          name="keywords"
          content={t("appKeywords", { ns: "common", returnObjects: true })}
        />
        <meta
          name="robots"
          content={
            process.env.NEXT_PUBLIC_STAGE === "production"
              ? "index, follow"
              : "noindex, nofollow"
          }
        />
        <meta name="language" content={locale} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaTitle} />
        <meta
          property="og:image"
          content={`${getBaseURL()}/images/og-image.jpeg`}
        />
        <meta
          property="og:description"
          content={t("appDescription", { ns: "common" })}
        />
        <meta property="og:url" content={getBaseURL()} />

        {/* Favicon */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicons/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicons/safari-pinned-tab.svg"
          color={variables["logo-color"]}
        />
        <link rel="shortcut icon" href="/favicons/favicon.ico" />
        <meta
          name="msapplication-TileColor"
          content={variables["logo-color"]}
        />
        <meta
          name="msapplication-config"
          content="/favicons/browserconfig.xml"
        />
        <meta name="theme-color" content={variables["white"]} />
      </Head>
      <Navbar />
      <main className={`${light ? "" : "bg-light"} d-flex flex-column flex-1`}>
        <Container
          fluid={wide}
          className={`flex-1 ${
            center &&
            "d-flex flex-column w-100 justify-content-center align-items-center"
          } ${className}`}
        >
          {children}
        </Container>
        {["production", "development"].includes(
          process.env.NEXT_PUBLIC_STAGE as string,
        ) && (
          <CookieBot
            domainGroupId={t("cookieConsentGroupId")}
            language={locale}
          />
        )}
      </main>

      <Footer />
    </div>
  );
};



export default Layout;
