import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Globe } from "react-feather";
import { Col, Container, Row } from "reactstrap";
import Logo, { LogoColors, LogoTextColors } from "../../_atoms/Logo/Logo";
import styles from "./Footer.module.scss";

export const Footer: React.FC = () => {
  const { t } = useTranslation("common");
  const { pathname, push, locale, query, asPath } = useRouter();

  return (
    <footer className="py-8 border-top">
      <Container fluid="lg">
        <Row>
          <Col xs="12" md="2" className="d-flex justify-content-center">
            <Logo
              color={LogoColors.Green}
              className={`${styles.logo}`}
              textColor={LogoTextColors.Black}
            />
          </Col>
          <Col className={styles.links}>
            <ul className="list-inline mt-5 mt-md-0 mb-0">
              {(
                t("footer.links", { returnObjects: true }) as {
                  text: string;
                  href: string;
                }[]
              )?.map((item, k) => (
                <li className="list-inline-item mx-6 my-2 my-md-0" key={k}>
                  <a href={item.href}>
                    <span className="text-dark">{item.text}</span>
                  </a>
                </li>
              ))}
              <li
                className="list-inline-item mx-6 my-2 my-md-0"
                onClick={(e) => {
                  e.preventDefault();
                  push({ pathname, query }, asPath, {
                    locale: locale === "de" ? "en" : "de",
                  });
                }}
              >
                <div className={`d-flex align-items-center ${styles.hover}`}>
                  {locale === "de" ? "EN" : "DE"}
                  <Globe className="mx-2" />
                </div>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
