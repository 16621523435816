export enum ProtectedRouteCondition {
  SIGNED_IN,
  SIGNED_OUT,
  FORBIDDEN,
}

export type Route = {
  path: string;
  condition?: ProtectedRouteCondition;
  redirect?: string;
};

const routes: { [key: string]: Route } = {
  signin: {
    path: "/auth/signin",
    condition: ProtectedRouteCondition.SIGNED_OUT,
  },
  signup: {
    path: "/auth/register",
    condition: ProtectedRouteCondition.SIGNED_OUT,
  },
  passwordReset: {
    path: "/auth/password/reset",
  },
  // preferences: {
  //   path: "/account/preferences",
  //   condition: ProtectedRouteCondition.SIGNED_IN,
  // },
  home: {
    path: "/",
    condition: ProtectedRouteCondition.SIGNED_IN,
  },
  projects: {
    path: "/projects",
    condition: ProtectedRouteCondition.SIGNED_IN,
  },
  projectCreate: {
    path: "/projects/create",
    condition: ProtectedRouteCondition.SIGNED_IN,
  },
  projectDelete: {
    path: "/projects/create",
    condition: ProtectedRouteCondition.SIGNED_IN,
  },
  agbs: {
    path: "/agbs"
  },
};

export const apiRoutes: {
  auth: Record<string, Route>;
  calendly: Record<string, Route>;
  db: Record<string, Route>;
} = {
  auth: {
    signupConfirmationHandler: {
      path: "/api/auth/verifyUserEmail",
    },
  },
  calendly: {
    getEvent: {
      path: "/api/calendly/getEvent",
    },
    cancelEvent: {
      path: "/api/calendly/cancelEvent",
    },
  },
  db: {
    projects: {
      path: "/api/db/projects",
    },
  },
};

export default routes;
